// @ts-nocheck
import { Grid, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import ArenaIcon from "assets/images/arena.svg";
import ChallengeBtn from "assets/images/challenge/challenge-icon.png";
import CheckinIcon from "assets/images/check-in.svg";
import CupIcon from "assets/images/cup.svg";
import EventIcon from "assets/images/event.svg";
import HomeIcon from "assets/images/home.svg";
import SoundOff from "assets/images/icon-sound-off.png";
import SoundOn from "assets/images/icon-sound-on.png";
import InventoryOpen from "assets/images/inventory-open.svg";
import NotificationIcon from "assets/images/notification-icon.png";
import WarningIcon from "assets/images/warning-icon.png";
import WheelIcon from "assets/images/wheel.svg";
import ArenaWinModal from "components/Modal/ArenaWinModal";
import AwardedModal from "components/Modal/AwardedModal";
import CheckinModal from "components/Modal/CheckinModal";
import GetABetModal from "components/Modal/GetABetModal";
import ConfirmRemoveParticipant from "components/Modal/ConfirmRemoveParticipant";
import GiftBoxModal from "components/Modal/GiftBoxModal";
import GiftBoxSevenDetailModal from "components/Modal/GiftBoxSevenDetailModal";
import GiftBoxSeventModal from "components/Modal/GiftBoxSeventModal";
import InvenExchangeModal from "components/Modal/InvenExchangeModal";
import InventoryModal from "components/Modal/InventoryModal";
import ListNotificationModal from "components/Modal/ListNotificationModal";
import Loading from "components/Modal/Loading";
import UseItemsModal from "components/Modal/UseItemsModal";
import WheelModal from "components/Modal/WheelModal";
import AudioPlayer from "components/Music/AudioPlayer";
import { colors } from "config/colors/colors";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  asyncAttendance,
  asyncListAttendance,
} from "redux/attendances/attendance.actions";
import { setIsAttendance } from "redux/attendances/attendance.slice";
import {
  asyncNewestNotification,
  getListNotification,
} from "redux/events/events.actions";
import { fetchUserCurrency } from "redux/inventory/inven.action";
import { invenSelector } from "redux/inventory/inven.selector";
import { setAwardedUser } from "redux/inventory/inven.slice";
import {
  setBackHome,
  setInfoEquipmentDetail,
  setInfoGiftBox,
  setInfoNotification,
  setMessageError,
  setNotiAnchor,
  setOpenABet,
  setOpenArenaWinModal,
  setOpenChallengRoomModal,
  setOpenExchange,
  setOpenGiftBoxModal,
  setOpenGiftBoxSeventModal,
  setOpenInven,
  setOpenLeaderboardModal,
  setOpenModalCheckin,
  setOpenModalGiftBoxSevenDetail,
  setOpenNotification,
  setOpenUpRankModal,
  setOpenUseItemModal,
  setOpenWheelModal,
  setPlayMusic,
  setUnread,
  setOpenRemoveParticipant,
} from "redux/popup/popup.slice";
import { io } from "socket.io-client";
import HuyChuongVang from "../../assets/images/huy-chuong-vang.png";
import "../../config/styles/animation_text.css";
import { authTokenSelector } from "../../redux/auth/auth.selectors";
import { setTokenAction } from "../../redux/auth/auth.slice";
import {
  LOCALE_KEY,
  setStorageValue,
} from "../../redux/localStorage/useLocalStorage";
import { showPopup } from "../../redux/popup/popup.action";
import {
  isPlayMusicSelector,
  openChallengRoomModalSelector,
  openLeaderboardModalSelector,
  openUpRankModalSelector,
  popupABetSelector,
  popupAwardedSelector,
  popupEquipmentDetailSelector,
  popupGiftBoxSelector,
  popupGiftBoxSevenDetailSelector,
  popupGiftBoxSevenSelector,
  popupSelector,
  popupUseItemSelector,
  popupRemoveParticipantSelector,
} from "../../redux/popup/popup.selector";
import {
  setOpen,
  setOpenModalAwarded,
  setOpenModalEquipmentDetail,
} from "../../redux/popup/popup.slice";
import {
  asyncGetConfigIndex,
  checkAuthenAction,
} from "../../redux/users/user.actions";
import { userSelector } from "../../redux/users/user.selectors";
import HeaderBtn from "../Buttons/HeaderButton";
import NotifiModal from "../Modal/NotifiModal";
import Profile from "../Profile";
import DetailChallengeModal from "components/Challenge/DetailChallengeModal";
import InfoUpRank from "components/Modal/InfoUpRank";
import LeaderboardChallenge from "components/Challenge/LeaderboardChallenge";
import {useNavigate} from "react-router-dom";

function AnimationText({ notificationAnimation }) {
  return (
    <div id="titles" key={notificationAnimation?.id ?? 0}
      style={{
        width: '100%',
      }}
    >
      <div id="titlecontent"
        style={{
          position: 'relative',
        }}
      >
        <p
          className="jump"
          style={{
            alignSelf: "center",
            fontFamily: "SVN-Gotham",
            fontSize: 16,
            textShadow: `0px 2px 0px rgba(0,0,0,0.5)`,
            display: "flex",
            alignItems: "center",
            marginBottom: "4px",
          }}
        >
          <span>❤️</span>
          <span>THÔNG</span>
          <span>❤️</span>
          <span>BÁO</span>
          <span>❤️</span>
        </p>

        <p
          style={{
            color: "#FFEEA3",
            // backgroundImage: `radial-gradient(circle, #9b4a22, #8a411d, #7a3918, #6a3014, #5b280f)`,
            borderRadius: 10,
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            alignSelf: "center",
            fontFamily: "SVN-Gotham",
            fontSize: 15,
            textShadow: `0px 2px 0px rgba(0,0,0,0.5)`,
            display: "flex",
            alignItems: "center",
            paddingLeft: "8px",
          }}
        >
          {notificationAnimation?.data?.title ?? ""}
        </p>
      </div>
    </div>
  );
}

const MemoizedMovie2 = React.memo(AnimationText);

function NavBar() {
  const navigate = useNavigate();
  const profileUser = useSelector(userSelector);
  const tokenAuth = useSelector(authTokenSelector);
  const isOpen = useSelector(popupSelector);
  const isOpentEquipmentDetail = useSelector(popupEquipmentDetailSelector);
  const openChallengRoomModal = useSelector(openChallengRoomModalSelector);
  const openUpRankModal = useSelector(openUpRankModalSelector);
  const openLeaderboardModal = useSelector(openLeaderboardModalSelector);

  const isOpentAwarded = useSelector(popupAwardedSelector);
  const isPlayAudio = useSelector(isPlayMusicSelector);
  const {
    isOpenExchangeModal,
    isOpenCheckinModal,
    isOpenArenaWinModal,
    isOpenWheelModal,
    isOpenLoading,
    isOpenNotification,
    unread,
  } = useSelector(popupSelector);
  const isOpenGiftBoxSevenDetail = useSelector(popupGiftBoxSevenDetailSelector);
  const isOpenGiftBox = useSelector(popupGiftBoxSelector);
  const isOpenGiftBoxSeven = useSelector(popupGiftBoxSevenSelector);
  const isOpenABet = useSelector(popupABetSelector);
  const isOpenRemoveParticipant = useSelector(popupRemoveParticipantSelector);
  const isUseItemModal = useSelector(popupUseItemSelector);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { currency } = useSelector(invenSelector);
  const [socket, setSocket] = useState(null);
  const [listNotifiAnimation, setListNotifiAnimation] = useState([]);

  const handleCheckAuth = async () => {
    await dispatch(checkAuthenAction()).unwrap();
  };

  const asyncGetListNotification = async () => {
    await dispatch(getListNotification(1))
      .unwrap()
      .then((res) => {
        if (res?.data?.length > 0) {
          dispatch(setUnread(res?.unread));
        }
      })
      .catch((rejectedValueOrSerializedError) => {});
  };

  useEffect(() => {
    const locationToken = window?.location?.href;
    const tokenWeb = new URLSearchParams(locationToken.split("?")[1]).get(
      "token"
    );

    if (!tokenAuth && tokenWeb) {
      setStorageValue(LOCALE_KEY.token, tokenWeb);
      dispatch(setTokenAction(tokenWeb));
    }

    if (tokenAuth && tokenWeb && tokenWeb !== tokenAuth) {
      setStorageValue(LOCALE_KEY.token, tokenWeb);
      dispatch(setTokenAction(tokenWeb));
    }

    dispatch(fetchUserCurrency());
    asyncGetListNotification();
  }, []);

  useEffect(() => {
    if (tokenAuth) {
      handleCheckAuth();
      const BASE_URL_SOKET_IO = process.env.REACT_APP_API_SOKET_IO;
      setSocket(io(`${BASE_URL_SOKET_IO}?token=${tokenAuth}`));
      dispatch(asyncNewestNotification())
        .unwrap()
        .then((result) => {
          if (!isOpenArenaWinModal && result && result?.data?.origin === 1) {
            dispatch(setInfoNotification(result));
            dispatch(setOpenArenaWinModal(true));
          }
        });

      dispatch(asyncListAttendance())
        .unwrap()
        .then((result) => {
          if (!result?.isAttendance) {
            getListAttendance();
          }
        });
      dispatch(asyncGetConfigIndex());
    }
  }, [tokenAuth]);

  useEffect(() => {
    socket?.on("notification:list", (data) => {
      if (data) {
        dispatch(setInfoNotification(data));
        setListNotifiAnimation([...listNotifiAnimation, data]);
        asyncGetListNotification();
        if (data?.data?.origin === 1 || data?.data?.origin === 6) {
          dispatch(setOpenUpRankModal(false));
          if (data?.data?.receiverId === profileUser?.id) {
            dispatch(setOpenArenaWinModal(true));
          }
        }
      }
    });

    return () => {
      socket?.off("notification:list");
    };
  }, [socket, profileUser]);

  const ShowModal = () => {
    dispatch(setOpen(true));
  };

  const ShowInven = () => {
    dispatch(setOpenInven(true));
  };

  AudioPlayer();

  const getListAttendance = () => {
    dispatch(setOpenModalCheckin(true));
  };

  const redirectRankingPage = () => {
    navigate('/ranking-all');
  }

  return tokenAuth ? (
    <AppBar
      position="static"
      sx={{
        backgroundColor: colors.bg_black,
      }}
    >
      {/* {isLoading ? <LoadingSpinner /> : null} */}
      <NotifiModal
        open={isOpen.isOpen}
        onClose={() => {
          dispatch(showPopup(false));
        }}
      />
      <InventoryModal
        open={isOpen.isOpenInven}
        onClose={() => dispatch(setOpenInven(false))}
        clickItem={(item) => {
          dispatch(setInfoEquipmentDetail(item));
          dispatch(setOpenModalEquipmentDetail(true));
        }}
      />
      <InvenExchangeModal
        open={isOpenExchangeModal}
        onClose={() => dispatch(setOpenExchange(false))}
      />
      {isUseItemModal ? (
        <UseItemsModal
          open={isUseItemModal}
          onClose={() => dispatch(setOpenUseItemModal(false))}
        />
      ) : null}

      {isOpentAwarded ? (
        <AwardedModal
          open={isOpentAwarded}
          onClose={() => {
            dispatch(setOpenModalAwarded(false));
            dispatch(setAwardedUser({}));
          }}
          onClickBtnLeft={() => {
            dispatch(setOpenModalAwarded(false));
          }}
        />
      ) : null}
      {isOpenGiftBoxSevenDetail ? (
        <GiftBoxSevenDetailModal
          open={isOpenGiftBoxSevenDetail}
          onClose={() => dispatch(setOpenModalGiftBoxSevenDetail(false))}
        />
      ) : null}
      {isOpenCheckinModal ? (
        <CheckinModal
          open={isOpenCheckinModal}
          onClose={() => dispatch(setOpenModalCheckin(false))}
          onClickCheckin={(itemIsDay) => {
            dispatch(setInfoGiftBox(itemIsDay));
            dispatch(asyncAttendance())
              .unwrap()
              .then((result) => {
                if (result.success) {
                  dispatch(setOpenGiftBoxModal(true));
                  dispatch(setIsAttendance(true));
                } else {
                  dispatch(setBackHome());
                  dispatch(setMessageError(result?.message ?? "Đã có lỗi, vui lòng thử lại sau."));
                  dispatch(showPopup(true));
                }
              });
          }}
        />
      ) : null}

      {isOpenArenaWinModal ? (
        <ArenaWinModal
          open={isOpenArenaWinModal}
          onClose={() => {
            dispatch(setOpenArenaWinModal(false));
            dispatch(setInfoNotification({}));
          }}
        />
      ) : null}
      {openUpRankModal ? (
        <InfoUpRank
          open={openUpRankModal}
          onClose={() => {
            dispatch(setOpenUpRankModal(false));
          }}
        />
      ) : null}

      {openLeaderboardModal ? (
        <LeaderboardChallenge
          open={openLeaderboardModal}
          onClose={() => dispatch(setOpenLeaderboardModal(false))}
        />
      ) : null}

      <WheelModal
        open={isOpenWheelModal}
        onClose={() => dispatch(setOpenWheelModal(false))}
      />
      <GiftBoxModal
        open={isOpenGiftBox}
        onClose={() =>
          dispatch(
            // @ts-ignore
            setOpenGiftBoxModal(false)
          )
        }
        onClickbackHome={() => {
          dispatch(setBackHome({}));
        }}
        onClickEquipment={() => {
          dispatch(setBackHome({}));
          dispatch(setOpenInven(true));
        }}
      />
      <GiftBoxSeventModal
        open={isOpenGiftBoxSeven}
        onClose={() => dispatch(setOpenGiftBoxSeventModal(false))}
        onClickOpenNow={async () => {
          dispatch(setOpenGiftBoxSeventModal(false));
          await dispatch(
            setInfoGiftBox({
              title: "Huy chương vàng 7",
              icon: HuyChuongVang,
              checkBoxGiftSevent: false,
            })
          );
          dispatch(setOpenGiftBoxModal(true));
        }}
        onClickbackHome={() => {
          dispatch(setBackHome({}));
        }}
      />
      <GetABetModal
        open={isOpenABet}
        onClose={() => dispatch(setOpenABet(false))}
      />
      <ConfirmRemoveParticipant
        open={isOpenRemoveParticipant}
        onClose={() => dispatch(setOpenRemoveParticipant(false))}
      />
      <Loading
        open={isOpenLoading}
        onClose={() => dispatch(setIsLoading(false))}
      />

      <ListNotificationModal
        open={isOpenNotification}
        onClose={() => dispatch(setOpenNotification(false))}
      />
      {openChallengRoomModal ? (
        <DetailChallengeModal
          open={openChallengRoomModal}
          onClose={() => {
            dispatch(setOpenChallengRoomModal(false));
          }}
        />
      ) : null}

      <Container maxWidth={false} style={{ padding: 8 }}>
        <Toolbar disableGutters>
          <Grid container columnSpacing={3} position='relative'>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              {" "}
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                }}
              >
                <Link
                  to={{ pathname: "/" }}
                  onClick={() => dispatch(checkAuthenAction())}
                >
                  {profileUser?.rank ? (
                    <div style={{ position: "relative" }}>
                      <img
                        src={profileUser?.rank?.circleAvatar}
                        alt="avatar"
                        style={{
                          margin: "auto",
                          borderRadius: "50%",
                          backgroundColor: "gray",
                          position: "absolute",
                          top: 39,
                          left: 33,
                          width: 64,
                          height: 64,
                        }}
                      />
                      <img
                        src={profileUser?.rank?.frame ?? ""}
                        width={130}
                        style={{ maxWidth: "none" }}
                        alt="..."
                      />
                    </div>
                  ) : (
                    <div style={{ position: "relative" }}>
                      <img src={WarningIcon} width={130} alt="..." />
                    </div>
                  )}
                </Link>
                <Profile />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex", justifyContent: "center",
                position: 'absolute',
                width: '400px',
                height: '100%',
                bottom: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 10,
              }}
            >
              {" "}
              <Box sx={{ width: "100%", height: "100%" }}>
                {listNotifiAnimation?.length
                  ? listNotifiAnimation.map((value, i) => (
                      <MemoizedMovie2 key={i} notificationAnimation={value} />
                    ))
                  : null}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Box
              // sx={{
              //   flexGrow: 1,
              //   marginBottom: "auto",
              // }}
              // display="flex"
              // flexDirection="column"
              >
                <Grid container sx={{ display: "flex", justifyContent: "end" }}>
                  <Grid
                    item
                    sx={{zIndex: 10}}
                  >
                    {/* <Link to={{ pathname: "/home" }}> */}
                    <div onClick={ShowModal}>
                      <img
                        src={HomeIcon}
                        alt="..."
                        style={{ marginTop: "10px", marginLeft: "12px" }}
                      />
                    </div>
                    {/* </Link> */}
                  </Grid>
                  {currency && (
                    <Grid item sx={{marginLeft: '10px'}}>
                      <HeaderBtn
                        label={currency?.quantity ?? "0"}
                        onClick={undefined}
                        icon={currency?.image}
                        type={"coin"}
                        style={{
                          maxWidth: "40px",
                          height: "40px",
                          left: "-7px",
                        }}
                      />
                    </Grid>
                  )}

                  <Grid item
                        sx={{
                          cursor: 'pointer',
                          zIndex: 10,
                        }}
                        onClick={() => redirectRankingPage()}
                  >
                    <HeaderBtn
                      onClick={undefined}
                      icon={CupIcon}
                      label={"BXH"}
                      type={"leaderboard"}
                      showLabel={true}
                    />
                  </Grid>

                  <Grid item>
                    <div onClick={ShowInven}>
                      <HeaderBtn
                        onClick={undefined}
                        icon={InventoryOpen}
                        label={"Trang bị"}
                        type={"inventory"}
                        showLabel={true}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <div onClick={() => getListAttendance()}>
                      <HeaderBtn
                        onClick={undefined}
                        icon={CheckinIcon}
                        label={"Báo danh"}
                        type={"checkin"}
                        showLabel={true}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        marginLeft: "12px",
                        cursor: "pointer",
                        marginTop: "14px",
                      }}
                      onClick={() => dispatch(setPlayMusic(!isPlayAudio))}
                    >
                      {isPlayAudio ? (
                        <img src={SoundOn} alt="..." />
                      ) : (
                        <img src={SoundOff} alt="..." />
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  container
                  flexDirection="row"
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <Grid item>
                    <Link to="/challenge">
                      {/* <div onClick={ShowModal}> */}
                      <HeaderBtn
                        onClick={undefined}
                        icon={ChallengeBtn}
                        label={"Thách đấu"}
                        type={"challenge"}
                        showLabel={true}
                      />
                      {/* </div> */}
                    </Link>
                  </Grid>

                  <Grid item>
                    <Link to="/arena">
                      <HeaderBtn
                        onClick={undefined}
                        icon={ArenaIcon}
                        label={"Đấu trường"}
                        type={"arena"}
                        showLabel={true}
                      />
                    </Link>
                  </Grid>

                  <Grid item>
                    <Link to="/events">
                      <HeaderBtn
                        onClick={undefined}
                        icon={EventIcon}
                        label={"Sự kiện"}
                        type={"event"}
                        showLabel={true}
                      />
                    </Link>
                  </Grid>
                  <Grid item>
                    <div onClick={ShowModal}>
                      {/* <div onClick={() => dispatch(setOpenArenaWinModal(true))}> */}
                      <HeaderBtn
                        onClick={undefined}
                        icon={WheelIcon}
                        label={"Quay thưởng"}
                        type={"wheel"}
                        showLabel={true}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        marginLeft: "12px",
                        marginTop: "14px",
                        cursor: "pointer",
                      }}
                      onClick={(event) => {
                        dispatch(setOpenNotification(true));
                        dispatch(setNotiAnchor(event.currentTarget));
                      }}
                    >
                      <img src={NotificationIcon} alt="..." width="40px" />
                      {unread > 0 ? (
                        <Box
                          sx={{
                            width: unread >= 100 ? "18px" : "16px",
                            height: unread >= 100 ? "18px" : "16px",
                            borderRadius: "20px",
                            backgroundColor: "#FFEEA3",
                            position: "relative",
                            right: "-26px",
                            top: unread >= 100 ? "-45px" : "-46px",
                            zIndex: 2,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontFamily: "Be-Vietnam-Pro",
                              color: "saddlebrown",
                              fontSize: unread >= 100 ? 8 : 9,
                              textAlign: "center",
                            }}
                          >
                            {unread >= 100 ? "99+" : unread}
                          </Typography>
                        </Box>
                      ) : null}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  ) : (
    <Box sx={{ height: "100%" }}></Box>
  );
}
export default NavBar;
